import { default as abouty5lRfI283UMeta } from "/home/forge/app.hd-institute.com/releases/20241001101303/pages/about.vue?macro=true";
import { default as loginKxffqzfJ4wMeta } from "/home/forge/app.hd-institute.com/releases/20241001101303/pages/auth/login.vue?macro=true";
import { default as faqEcNpzK6JfCMeta } from "/home/forge/app.hd-institute.com/releases/20241001101303/pages/faq.vue?macro=true";
import { default as indexVcfVhJUuAdMeta } from "/home/forge/app.hd-institute.com/releases/20241001101303/pages/index.vue?macro=true";
import { default as _1_46startANrCZJ9295Meta } from "/home/forge/app.hd-institute.com/releases/20241001101303/pages/quiz/form/1.start.vue?macro=true";
import { default as _2_46sessionYm1MPuXWE3Meta } from "/home/forge/app.hd-institute.com/releases/20241001101303/pages/quiz/form/2.session.vue?macro=true";
import { default as _3_46finish1GaOZttS2cMeta } from "/home/forge/app.hd-institute.com/releases/20241001101303/pages/quiz/form/3.finish.vue?macro=true";
import { default as formGt76ejrThAMeta } from "/home/forge/app.hd-institute.com/releases/20241001101303/pages/quiz/form.vue?macro=true";
import { default as homeKg7JROeCqvMeta } from "/home/forge/app.hd-institute.com/releases/20241001101303/pages/quiz/home.vue?macro=true";
import { default as onboardingE40PCyM6OqMeta } from "/home/forge/app.hd-institute.com/releases/20241001101303/pages/quiz/onboarding.vue?macro=true";
import { default as preparelEkpmnfqiJMeta } from "/home/forge/app.hd-institute.com/releases/20241001101303/pages/quiz/prepare.vue?macro=true";
import { default as _1_46create_45teamsB005D5VtfhMeta } from "/home/forge/app.hd-institute.com/releases/20241001101303/pages/quiz/quiz/1.create-teams.vue?macro=true";
import { default as _2_46teamsz8LYbHczyaMeta } from "/home/forge/app.hd-institute.com/releases/20241001101303/pages/quiz/quiz/2.teams.vue?macro=true";
import { default as _3_46sessionqM7msdrid1Meta } from "/home/forge/app.hd-institute.com/releases/20241001101303/pages/quiz/quiz/3.session.vue?macro=true";
import { default as _4_46resultschNhKy6H1VMeta } from "/home/forge/app.hd-institute.com/releases/20241001101303/pages/quiz/quiz/4.results.vue?macro=true";
import { default as select4SJKNDLgBkMeta } from "/home/forge/app.hd-institute.com/releases/20241001101303/pages/quiz/select.vue?macro=true";
import { default as finishSU2jrHri0aMeta } from "/home/forge/app.hd-institute.com/releases/20241001101303/pages/scan/finish.vue?macro=true";
import { default as homeYxCoa9njlBMeta } from "/home/forge/app.hd-institute.com/releases/20241001101303/pages/scan/home.vue?macro=true";
import { default as onboardingcf1jqh7pKHMeta } from "/home/forge/app.hd-institute.com/releases/20241001101303/pages/scan/onboarding.vue?macro=true";
import { default as prepareTRWhUJVEW5Meta } from "/home/forge/app.hd-institute.com/releases/20241001101303/pages/scan/prepare.vue?macro=true";
import { default as resumeYqhU1VcZBIMeta } from "/home/forge/app.hd-institute.com/releases/20241001101303/pages/scan/resume.vue?macro=true";
import { default as scanbhMgyRrYkKMeta } from "/home/forge/app.hd-institute.com/releases/20241001101303/pages/scan/scan.vue?macro=true";
import { default as select8M1ZXnvUqHMeta } from "/home/forge/app.hd-institute.com/releases/20241001101303/pages/scan/select.vue?macro=true";
import { default as settingsewIE08nvH4Meta } from "/home/forge/app.hd-institute.com/releases/20241001101303/pages/settings.vue?macro=true";
import { default as systemA9qs8Z5uUMMeta } from "/home/forge/app.hd-institute.com/releases/20241001101303/pages/system.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    meta: abouty5lRfI283UMeta || {},
    component: () => import("/home/forge/app.hd-institute.com/releases/20241001101303/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginKxffqzfJ4wMeta || {},
    component: () => import("/home/forge/app.hd-institute.com/releases/20241001101303/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/home/forge/app.hd-institute.com/releases/20241001101303/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "home",
    path: "/",
    meta: indexVcfVhJUuAdMeta || {},
    component: () => import("/home/forge/app.hd-institute.com/releases/20241001101303/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "quiz-form",
    path: "/quiz/form",
    component: () => import("/home/forge/app.hd-institute.com/releases/20241001101303/pages/quiz/form.vue").then(m => m.default || m),
    children: [
  {
    name: "quiz-form-1.start",
    path: "1.start",
    component: () => import("/home/forge/app.hd-institute.com/releases/20241001101303/pages/quiz/form/1.start.vue").then(m => m.default || m)
  },
  {
    name: "quiz-form-2.session",
    path: "2.session",
    component: () => import("/home/forge/app.hd-institute.com/releases/20241001101303/pages/quiz/form/2.session.vue").then(m => m.default || m)
  },
  {
    name: "quiz-form-3.finish",
    path: "3.finish",
    component: () => import("/home/forge/app.hd-institute.com/releases/20241001101303/pages/quiz/form/3.finish.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "quiz-home",
    path: "/quiz/home",
    component: () => import("/home/forge/app.hd-institute.com/releases/20241001101303/pages/quiz/home.vue").then(m => m.default || m)
  },
  {
    name: "quiz-onboarding",
    path: "/quiz/onboarding",
    component: () => import("/home/forge/app.hd-institute.com/releases/20241001101303/pages/quiz/onboarding.vue").then(m => m.default || m)
  },
  {
    name: "quiz-prepare",
    path: "/quiz/prepare",
    component: () => import("/home/forge/app.hd-institute.com/releases/20241001101303/pages/quiz/prepare.vue").then(m => m.default || m)
  },
  {
    name: "quiz-quiz-1.create-teams",
    path: "/quiz/quiz/1.create-teams",
    component: () => import("/home/forge/app.hd-institute.com/releases/20241001101303/pages/quiz/quiz/1.create-teams.vue").then(m => m.default || m)
  },
  {
    name: "quiz-quiz-2.teams",
    path: "/quiz/quiz/2.teams",
    component: () => import("/home/forge/app.hd-institute.com/releases/20241001101303/pages/quiz/quiz/2.teams.vue").then(m => m.default || m)
  },
  {
    name: "quiz-quiz-3.session",
    path: "/quiz/quiz/3.session",
    component: () => import("/home/forge/app.hd-institute.com/releases/20241001101303/pages/quiz/quiz/3.session.vue").then(m => m.default || m)
  },
  {
    name: "quiz-quiz-4.results",
    path: "/quiz/quiz/4.results",
    component: () => import("/home/forge/app.hd-institute.com/releases/20241001101303/pages/quiz/quiz/4.results.vue").then(m => m.default || m)
  },
  {
    name: "quiz-select",
    path: "/quiz/select",
    component: () => import("/home/forge/app.hd-institute.com/releases/20241001101303/pages/quiz/select.vue").then(m => m.default || m)
  },
  {
    name: "scan-finish",
    path: "/scan/finish",
    component: () => import("/home/forge/app.hd-institute.com/releases/20241001101303/pages/scan/finish.vue").then(m => m.default || m)
  },
  {
    name: "scan.home",
    path: "/scan/home",
    meta: homeYxCoa9njlBMeta || {},
    component: () => import("/home/forge/app.hd-institute.com/releases/20241001101303/pages/scan/home.vue").then(m => m.default || m)
  },
  {
    name: "scan-onboarding",
    path: "/scan/onboarding",
    component: () => import("/home/forge/app.hd-institute.com/releases/20241001101303/pages/scan/onboarding.vue").then(m => m.default || m)
  },
  {
    name: "scan.prepare",
    path: "/scan/prepare",
    meta: prepareTRWhUJVEW5Meta || {},
    component: () => import("/home/forge/app.hd-institute.com/releases/20241001101303/pages/scan/prepare.vue").then(m => m.default || m)
  },
  {
    name: "scan.resume",
    path: "/scan/resume",
    meta: resumeYqhU1VcZBIMeta || {},
    component: () => import("/home/forge/app.hd-institute.com/releases/20241001101303/pages/scan/resume.vue").then(m => m.default || m)
  },
  {
    name: "scan-scan",
    path: "/scan/scan",
    component: () => import("/home/forge/app.hd-institute.com/releases/20241001101303/pages/scan/scan.vue").then(m => m.default || m)
  },
  {
    name: "scan-select",
    path: "/scan/select",
    component: () => import("/home/forge/app.hd-institute.com/releases/20241001101303/pages/scan/select.vue").then(m => m.default || m)
  },
  {
    name: "settings",
    path: "/settings",
    meta: settingsewIE08nvH4Meta || {},
    component: () => import("/home/forge/app.hd-institute.com/releases/20241001101303/pages/settings.vue").then(m => m.default || m)
  },
  {
    name: "system",
    path: "/system",
    meta: systemA9qs8Z5uUMMeta || {},
    component: () => import("/home/forge/app.hd-institute.com/releases/20241001101303/pages/system.vue").then(m => m.default || m)
  }
]