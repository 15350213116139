import validate from "/home/forge/app.hd-institute.com/releases/20241001101303/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/home/forge/app.hd-institute.com/releases/20241001101303/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/home/forge/app.hd-institute.com/releases/20241001101303/middleware/auth.ts"),
  guest: () => import("/home/forge/app.hd-institute.com/releases/20241001101303/middleware/guest.ts"),
  "quiz-has-teams": () => import("/home/forge/app.hd-institute.com/releases/20241001101303/middleware/quiz-has-teams.ts"),
  "quiz-lifestyle-access": () => import("/home/forge/app.hd-institute.com/releases/20241001101303/middleware/quiz-lifestyle-access.ts"),
  "quiz-quiz-exists": () => import("/home/forge/app.hd-institute.com/releases/20241001101303/middleware/quiz-quiz-exists.ts"),
  "quiz-team-unfinished": () => import("/home/forge/app.hd-institute.com/releases/20241001101303/middleware/quiz-team-unfinished.ts")
}